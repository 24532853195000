.MuiDrawer-paper {
  position: relative !important;
  height: 100% !important;
}

html {
  scroll-behavior: smooth;
}

#sidebar {
  width: 235px;
}

#navbar {
  height: 70px;
}

#content {
  margin: 0px;
}

#footer {
  width: 100%;
  bottom: 0;
  position: absolute;
  height: 30px;
}

@media screen and (min-width: 900px) {
  #container {
    width: calc(100% - 235px);
    float: right;
    padding-bottom: 30px; /*footer's height*/
  }
  #content {
    margin-top: 54px;
    margin-right: 80px;
    margin-left: 80px;
  }
  #cookie-container {
    width: calc(100% - 235px);
  }
  #cookie {
    margin-right: 77px;
    margin-left: 77px;
  }
}

/* 2361 = 1920 + 270(sidebar) + 85(padding-left) + 85(padding-right) */
@media screen and (min-width: 2325px) {
  #container {
    display: flex;
    width: calc(100% - 270px);
    float: right;
    flex-direction: column;
  }
  #content {
    width: 1920px;
    margin: 0 auto;
    margin-top: 54px;
  }
  #sidebar {
    width: 270px;
  }
  #cookie-container {
    width: calc(100% - 270px);
  }
  #cookie {
    width: 1920px;
    margin: 0 auto;
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #a01441 !important;
}

/* REDOC STYLING */

/* --- Side menu --- */
.redoc-wrap > .menu-content {
  padding-top: 20px;
  position: sticky;
  top: 72px !important;
  height: calc(
    100vh - 70px - 30px
  ) !important; /* whole view except navbar and bottom CTA */
}

/* --- Side menu ReDoc credits --- */
.redoc-wrap > .menu-content a[target='_blank'] {
  display: none;
}

/* --- SpecFile Content (next to the side menu together with response samples) --- */
.redoc-wrap .api-content {
  padding-bottom: 72px;
}

/* --- Every section of the content --- */
.redoc-wrap .api-content > div[data-section-id^='section/'] {
  padding: 0px;
}

/* --- Every sub-section of a section (above) --- */
.redoc-wrap
  .api-content
  > div[data-section-id]
  > div[data-section-id^='section/'] {
  padding: 0px;
}

/* --- Right Side Response Sample content tab --- */
.redoc-wrap li[role='tab'] {
  background-color: transparent !important;
  border: 1px solid #3f3f3f;
}

/* --- Right Side Response Sample content --- */
.redoc-wrap div[role='tabpanel'] {
  background-color: #10181a;
  color: #a0a4a7;
}

/* --- >>> SPAN color within the Right Side Response <<< --- */
.redoc-wrap div[role='tabpanel'] span {
  color: #a0a4a7;
}
/* --- >>> Content Type box within the Right Side Response <<< --- */
.redoc-wrap div[role='tabpanel'] > div:first-child > div:first-child {
  background-color: #1a2127;
  color: #fbfbfb;
}
/* --- >>> Code Token color within the Right Side Response <<< --- */
.redoc-wrap .token.punctuation {
  color: #a0a4a7;
}

/* --- >>> Right Side Endpoint Path Arrow-Down Tab <<< --- */
.api-content > div > div:first-child > div:nth-child(2) > div:first-child {
  background-color: #1a2127;
  border-radius: 6px 6px 0px 0px;
  color: #a0a4a7;
}
/* --- >>> Right Side Endpoint Path Arrow-Down Tab Dropdown <<< --- */
.api-content
  > div
  > div:first-child
  > div:nth-child(2)
  > div:first-child
  > div {
  background-color: #1a2127;
  color: #a0a4a7;
}

.multi-select {
  --rmsc-main: #a01441 !important;
  --rmsc-p: 14px !important;
  --rmsc-radius: 4px !important;
  --rmsc-h: 56px !important;
}

.MuiMenuItem-root {
  font-size: 14px;
}

.MuiInputLabel-root {
  font-size: 14px;
}

.MuiAlert-message {
  padding: 8px 0 0 0 !important;
}

.MuiAlert-root {
  padding: 0px 6px !important;
}

.MuiRadio-root {
  color: black !important;
}

.MuiRadio-colorPrimary.Mui-checked {
  color: #a01441 !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #82A7A6 !important;
}

.MuiDropzonePreviewList-root {
  justify-content: center !important;
  margin: 0 !important;
  width: inherit !important;
}

.MuiDropzoneArea-root {
  min-height: 335px !important;
}

.MuiDropzoneArea-text {
  margin-bottom: 0 !important;
  font-size: 16px !important;
}

.MuiDropzonePreviewList-imageContainer {
  z-index: 0 !important;
  justify-content: center !important;
  width: fit-content !important;
  height: fit-content !important;
  min-width: 150px !important;
  min-height: 150px !important;
  padding: 0 !important;
  padding-bottom: 32px !important;
}

.MuiDropzoneArea-textContainer p,
.MuiDropzonePreviewList-imageContainer p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  min-height: 3em;
  word-break: break-word;
}

.MuiDropzonePreviewList-image {
  max-width: 150px !important;
  max-height: 150px !important;
}

.MuiInputBase-inputMultiline {
  min-height: 20px !important;
  border-radius: 0px !important;
}

.MuiStepper-root {
  padding-bottom: 0px !important;
}

.MuiContainer-maxWidthLg {
  max-width: 80% !important;
}

.CarouselItem {
  border-radius: 12px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.MuiSlider-rail {
  color: grey !important;
}

.MuiFormHelperText-root {
  color: #e74337 !important;
  margin-left: 14px;
}
